<template>
  <tr class="cursor-pointer bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100" :class="{'hidden' : !showThisRow}" >
    <td class="text-xs" :class="{'text-green-500': planner.status== 'submitted', 'text-green-800' : planner.status == 'approved'}">
      {{formattedStatus}}
    </td>
    <td>
      {{nameAddition}} {{planner.name}}
    </td>
    <td>
      <span v-if="participants && participants.length >= 1">{{participants.length}} directs</span>
      <span v-else-if="participants && participants.length == 0">N/A</span>
      <span v-else>N/A</span>
    </td>
    <td>
       <span v-for="approval in approvals" :key="approval.eeid">
         <div class="cursor-pointer mr-2 my-1 relative flex inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full bg-gray-100" :class="{'bg-green-100' : approval.status == 'approved'}">
           <span v-tippy="approverTooltip(approval)" class="font-medium text-gray-600">{{approverDetails(approval)}}</span>
         </div>
      </span>
    </td>
    <td>
      € {{new Intl.NumberFormat('en-US').format(planner.budget)}} <span class="text-xs">€ {{new Intl.NumberFormat('en-US').format(Math.round(budgetSpent))}} spent</span>
    </td>
    <td class="text-xs text-center">{{actionNeeded}}</td>
  </tr>
</template>


<script>
  import { formatAmount } from "../shared/utils";

  export default {
    name: 'PlannerDetail',
    props: {
      planner: Object,
      participants: {
        type: Array,
        required: false
      },
      approvals: Array,
      nameAddition: {
        type: String,
        required: false
      },
      user: {
        type: Object,
        required: true
      },
      plannerFilter: {
        type: String,
        required: false
      },
      cycle: {
        type: Object,
        required: true
      }
    },
    computed: {
      showThisRow() {
        if (this.plannerFilter == 'All') {
          return true
        }

        if (this.plannerFilter == 'Submitted') {
          return this.planner.status == 'submitted'
        }

        if (this.plannerFilter == 'Approved') {
          return this.planner.status == 'approved'
        }

        if (this.plannerFilter == 'Exceptions') {
          // loop over the participants and check if any of them have exceptions
          return this.participants.some(participant => {
            return participant.total_percentage_increase > (participant.max_recommendation + 0.4) || participant.new_compa_ratio > 1.2 || participant.current_variable_percentage != participant.new_variable_percentage
          })
        }

        return this.plannerFilter == 'Action Needed' && this.actionNeeded != 'N/A'
      },
      formattedStatus() {
        if (this.planner.status == 'draft') {
          return 'Not requested'
        }
        return this.planner.status.charAt(0).toUpperCase() + this.planner.status.slice(1)
      },
      actionNeeded() {
       // actions can be:
        // 1. No action needed
        // 2. Need to Approve a submitted planner
        // 3. Need to submit a planner
        // 4. TR needs to approve
        if (this.planner.status == 'requested' && this.planner.eeid == this.user.eeid) {
          return "Submission needed"
        }

        const currentPendingApproval = this.approvals.find(approval => approval.status == 'requested')
        if (currentPendingApproval && currentPendingApproval.approver_eeid == this.user.eeid) {
          return "Approval needed"
        }

        if (currentPendingApproval && currentPendingApproval.approver_eeid == null && currentPendingApproval.name == 'Total Rewards' && this.cycle.total_rewards_users.includes(this.user.eeid.toString())) {
          return "Approval from Total Rewards needed"
        }



        return "N/A"
      },
      budgetSpent() {
        return this.participants.reduce((total, participant) => {
          let baseSalaryDifference = 0
          let variablePayDifference = 0

          if (participant.new_base_salary_in_cycle_currency && participant.new_base_salary_in_cycle_currency != null && participant.new_base_salary_in_cycle_currency != 0) {
            baseSalaryDifference = Number(participant.new_base_salary_in_cycle_currency) - Number(participant.base_salary_cycle_currency)
          }

          if (participant.variable_pay_cycle_currency && participant.variable_pay_cycle_currency != null && participant.new_variable_pay_in_cycle_currency && participant.new_variable_pay_in_cycle_currency != null && participant.new_variable_pay_in_cycle_currency != 0) {
            variablePayDifference = Number(participant.new_variable_pay_in_cycle_currency) - Number(participant.variable_pay_cycle_currency)
          }

          const difference = baseSalaryDifference + variablePayDifference
          return total + difference
        }, 0)
      }
    },
    methods: {
      formatAmount,
      approverDetails(approval) {
        return approval.name.split(' ')[0][0] + approval.name.split(' ')[1][0]
      },
      approverTooltip(approval) {
        let status = approval.status.charAt(0).toUpperCase() + approval.status.slice(1)
        return `${approval.name} - ${status.replace('_', ' ')}`
      }
    }
  }
</script>