<template>
  <transition name="modal-fade w-full justify-center mx-auto m-2 overflow-auto">
    <div class="modal-backdrop w-full justify-center mx-auto m-2 overflow-auto">
      <div ref="modal" class="modal w-3/4 justify-center m-2 overflow-auto"
           tabindex="0"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @keydown.esc="close"
      >
        <header class="modal-header"  id="modalTitle">
          <p class="text-blaze-orange-600">Send back {{planner.name}}'s worksheet</p>
        </header>

        <section class="modal-body mx-4" id="modalDescription">
          <div class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative" role="alert">
            <strong class="font-bold">You will send back the worksheet with the following values:</strong>
            <ul>
              <li v-for="participant in participants" class="list-disc ml-8">
                <p v-if="participant.total_percentage_increase > 0.0">
                  {{participant.name}} receives a total percentage increase of {{participant.total_percentage_increase}}% resulting in a new compa ratio of {{Number(participant.new_compa_ratio).toFixed(2)}}
                </p>
                <p v-else>
                  {{participant.name}} receives no increase
                </p>
              </li>
            </ul>
          </div>

          <div class="mt-4" v-if="exceptionsFound">
            <div class="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative" role="alert">
              <strong class="font-bold">Exceptions found:</strong>
              <ul>
                <li v-for="participant in increaseExceptions" class="list-disc ml-8">
                  {{participant.name}} has a total percentage increase of {{participant.total_percentage_increase}}% which is greater than the max suggested of {{participant.max_recommendation}}%
                </li>
                <li v-for="participant in compaRatioExceptions" class="list-disc ml-8">
                  {{participant.name}} has a new compa ratio of {{Number(participant.new_compa_ratio).toFixed(2)}} which is greater than 1.20
                </li>

                <li v-for="participant in variablePayChangeExceptions" class="list-disc ml-8">
                  {{participant.name}} has a variable percentage pay change from {{participant.current_variable_percentage}}% to {{participant.new_variable_percentage}}%
                </li>
              </ul>
            </div>
          </div>

          <div class="mt-4">
            Reminder that you can leave a comment on each employee. This can help the planner understand why you are sending back the worksheet.
          </div>


        </section>

        <footer class="modal-footer">
          <p class="text-right text-sm">
            <button class="mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded " @click="reject">
              Send back worksheet
            </button>

            <button
                type="button"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                @click="close"
                aria-label="Close"
            >
              Close
            </button>
          </p>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'RejectModal',
  props: {
    participants: {
      type: Object,
      required: true,
    },
    planner: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    exceptionsFound() {
      if (this.increaseExceptions.length > 0 || this.compaRatioExceptions.length > 0 || this.variablePayChangeExceptions.length > 0) {
        return true
      }
      return false
    },

    increaseExceptions() {
      return this.participants.filter(participant => {
        return participant.total_percentage_increase > (participant.max_recommendation + 0.4)
      })
    },

    compaRatioExceptions() {
      return this.participants.filter(participant => {
        return participant.new_compa_ratio > 1.2
      })
    },

    variablePayChangeExceptions() {
      return this.participants.filter(participant => {
        return participant.current_variable_percentage != participant.new_variable_percentage
      })
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    reject() {
      this.$emit('reject');
    }
  },
};
</script>